<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
            <v-card v-if="!profile" light>
                <v-card-title>{{ $t('login.title') }}</v-card-title>
                <v-card-subtitle>{{ $t('login.description') }}</v-card-subtitle>
                <v-card-text v-if="isDevelopment">
                    <v-text-field :label="$t('users.name')" single-line v-model="email"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn v-if="isDevelopment" dark color="warning" @click="loginFromDev">
                        <v-icon left>mdi-cog</v-icon>
                        {{ $t('login.button_dev') }}
                    </v-btn>
                    <v-btn dark color="red" @click="loginFromMoovapps">
                        <v-img
                            class="shrink"
                            max-height="18"
                            contain
                            src="../../assets/Logo_blanc.png"
                            transition="scale-transition"
                        />
                        {{ $t('login.button_moovapps') }}
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'login',
    data: () => ({
        email: undefined,
    }),
    beforeMount() {
        if (this.profile) {
            if (this.$route.params.nextUrl) {
                this.saveRedirectPage(this.$route.params.nextUrl);
            }
            this.redirectToPage(this.profile);
        }
    },
    computed: {
        ...mapGetters(['token', 'profile']),
        isDevelopment() {
            return process.env.NODE_ENV === 'development';
        },
    },
    methods: {
        ...mapActions(['loadToken', 'loginToApp', 'loginToAppDev', 'saveRedirectPage', 'redirectToPage']),
        loginFromMoovapps() {
            const user = {
                _to: this.$route.params.nextUrl,
            };
            this.loginToApp(user);
        },
        loginFromDev() {
            const user = {
                email: this.email,
                _to: this.$route.params.nextUrl,
            };

            this.loginToAppDev(user);
        },
    },
};
</script>
